<template>
    <div>
        <filter-panel
            :selected-item="selectedCat"
            :items="categories"
            class="p-mb-5"
            @update="updateSelectedItems"
        ></filter-panel>
        <div class="p-d-flex p-flex-wrap">
            <project-card
                v-for="(item, index) in currentItems"
                :key="`project-${index}`"
                :tags="item.categories"
                :title="item.title"
                :authors="item.authors"
                :abstract="breakWord(item.abstract)"
                class="p-m-2"
                @button-click="goTo(`/project/${item.id}`)"
            ></project-card>
        </div>
        <div v-if="selectedItems.length > page.itemPerPage" class="p-d-flex p-jc-center p-mt-2 p-mt-md-4 p-mt-lg-6">
            <custom-button v-if="page.currentPage.value > 0" class="p-ml-4" width="160px" text="PREV" bg-color="white" border-color="#4878b0" @click="page.changePage(-1)"></custom-button>
            <custom-button v-if="page.currentPage.value < page.maxPage.value" class="p-ml-4" width="160px" text="NEXT" bg-color="white" border-color="#4878b0" @click="page.changePage(1)"></custom-button>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, computed, watch } from "vue";
    import { useRouter } from "vue-router";
    import CustomButton from "@/components/common/CustomButton";
    import ProjectCard from "@/components/project/ProjectCard";
    import FilterPanel from "@/components/project/FilterPanel";
    import { breakWord } from "@/utils/word";
    import { addPage } from "@/utils/page";
    import { apiExecutor } from "@/api";
    let self;

    export default {
        name: 'ProjectList',
        components: { CustomButton, ProjectCard, FilterPanel },
        setup() {
            const router = useRouter();
            const page = addPage(6);
            let projectItems = ref([]);
            let selectedCat = ref('');
            let categories = ref([]);
            let currentItems = ref([]);
            let selectedItems = computed(() => {
                return selectedCat.value === 'all' || selectedCat.value === '' ?
                    projectItems.value :
                    projectItems.value.filter((item) => item.categories.findIndex(cat => cat === selectedCat.value) !== -1) ;
            });

            onMounted(async () => {
                const catResult = await apiExecutor.getProjectCategories();
                projectItems.value = await apiExecutor.getIMProjectList();
                
                projectItems.value.forEach((val) => {
                    let obj = {
                        id : val.id,
                        images : val.image,
                        pub_id : val.pub_id.split(","),
                    };
                    self.$store.commit('setImage', obj);
                });

                categories.value = getCategories(catResult);
                currentItems.value = setCurrentItems(projectItems.value);
                page.maxPage.value = Math.round((projectItems.value.length) / page.itemPerPage);
            });

            watch(() => page.currentPage.value, () => {
                currentItems.value = setCurrentItems(selectedItems.value);
            });

            const setCurrentItems = function (items) {
                return items.slice(page.currentPage.value * page.itemPerPage, (page.currentPage.value + 1) * page.itemPerPage);
            };

            const getCategories = function (items) {
                return items.reduce((accumulator, current, index) => {
                    accumulator.push({ name: index === 0 ? 'all' : current.catName });
                    return accumulator;
                }, []);
            };

            const updateSelectedItems = function (value) {
                selectedCat.value = value;
                filterItems();
            };

            const filterItems = function () {
                page.currentPage.value = 0;
                currentItems.value = setCurrentItems(selectedItems.value);
            };

            const goTo = (path) => { router.push(path) };

            return { page, currentItems, selectedItems, categories, selectedCat, breakWord, goTo, updateSelectedItems };
        },
        created() {
            self = this;
        },
    }
</script>