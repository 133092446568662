<template>
    <div class="project-card white border-main p-p-3 p-d-flex p-flex-column p-jc-between">
        <div>
            <div class="title">
                <h4 class="p-mt-3 p-mb-1">{{ title }}</h4>
                <h5 class="p-mt-0 p-mb-1 text-second">{{ authors }}</h5>
            </div>
            <div class="p-my-2">
                <span
                    v-for="(tag, index) in tags"
                    :key="`tag-${index}`"
                    class="tag p-d-inline-block main text-white p-px-2 p-pb-1 p-pt-1 p-mb-1 p-mr-1"
                >{{ tag }}</span>
            </div>
            <!-- <div class="image second-l p-mb-3"></div> -->
            <p class="text-main p-mb-3">{{ abstract }}</p>
        </div>
        <custom-button text="READ MORE" width="100%" bg-color="white" border-color="#4878b0" @click="onClick"></custom-button>
    </div>
</template>

<script>
    import CustomButton from "../common/CustomButton";
    export default {
        name: 'ProjectCard',
        components: { CustomButton },
        props: {
            tags: Array,
            title: String,
            authors: String,
            abstract: String,
        },
        setup(props, { emit }) {
            const onClick = function () {
                emit('button-click');
            };

            return { onClick }
        }
    }
</script>

<style scoped lang="scss">
    .project-card {
        width: 360px;
        min-height: 300px;

        .image {
            padding-bottom: 50%;
            width: 100%;
        }

        p {
            font-size: 12px;
        }

        .tag{
            font-size: 12px;
            background-color: lightgrey;
        }
    }
</style>