<template>
    <div class="main p-p-5">
        <dropdown v-model="selected" :options="items" :filter="true" optionLabel="name" placeholder="Select category" />
    </div>
</template>

<script>
    import { ref, watch } from "vue";
    import Dropdown from 'primevue/dropdown';
    export default {
        name: 'FilterPanel',
        components: { Dropdown },
        props: { selectedItem: String, items: Object },
        setup(props, { emit }) {
            let selected = ref(props.selectedItem);

            watch(() => selected.value, (newVal) => {
                emit('update', newVal.name);
            });
            return { selected };
        },
        emits: ['update']
    }
</script>

<style lang="scss" scoped>
    .p-dropdown {
        width: 100%;
        border-radius: 0;
        border: none;
        padding: 10px 16px;
    }
</style>